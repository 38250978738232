import * as React from "react"

import ProjectTag from "../components/ProjectTag"

const projectTileStyles = {
    border: "2px solid black",
    display: "inline-block",
    maxWidth: "500px",
    minWidth: "300px",
    aspectRatio: "1",
    margin: "5px",
    padding: "2px",
    position: "relative",
}

const hnStyles = {
    margin: "2px 0 2px 0",
    textAlign: "center",
}

const hrStyle = {
    width: "50%"
}

const tagboxStyle = {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
}


const aStyle = {
    padding: "2px",
    background: "white",
    position: "absolute",
    right: "0",
    bottom: "0",
}

const atStyle = {
    textDecoration: "none",
    color: "black",
}

const imgStyle = {
    width: "100%",
}



function ProjectTile(prop) {
    return (
        <div className="project_tile" key={prop.name} style={projectTileStyles}>
            <a href={prop.link} style={atStyle}>
                <h2 style={hnStyles}>{prop.name}</h2>
                <hr style={hrStyle}/>
                <div style={tagboxStyle}>
                    {prop.tags.map(tag => ProjectTag(tag))}
                </div>
                <p>{prop.short}</p>
                <div>
                    {((prop.img == null) ? "" : (
                        <img src={prop.img} style={imgStyle} alt={prop.name}/>
                    ))} 
                    <a href={prop.link} style={aStyle}>Read More</a>
                </div>
            </a>
        </div>
    )
}

export default ProjectTile