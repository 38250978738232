import * as React from "react"
import {useState} from "react"
import ProjectTile from "../components/ProjectTile"
import ProjectTag from "../components/ProjectTag"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"

import AdventOfCodeThumbnail from "../images/project_thumbnails/adventofcode.png"
import AutoFomatterThumbnail from "../images/project_thumbnails/auto_formatter.png"
import ChilleniumThumbnail from "../images/project_thumbnails/chillenium.png"
import CS462Thumbnail from "../images/project_thumbnails/cs462.png"
import MarbleThumbnail from "../images/project_thumbnails/marblesorter.jpg"
import FrcThumbnail from "../images/project_thumbnails/frc.jpg"
import PolyPackThumbnail from "../images/project_thumbnails/polypack.png"
import Printer12dThumbnail from "../images/project_thumbnails/12dprinter.jpg"
import ReuThumbnail from "../images/project_thumbnails/reu.jpg"
import ScaraThumbnail from "../images/project_thumbnails/scara.jpg"
import ScaraWebappThumbnail from "../images/project_thumbnails/scara_webapp.png"
import SolidWorksWebappThumbnail from "../images/project_thumbnails/sw_webapp.png"
import SparkTumbnail from "../images/project_thumbnails/spark.png"
import SumobotsThumbnail from "../images/project_thumbnails/sumobots.png"
import UrsThumbnail from "../images/project_thumbnails/urs.png"
import VexUCoProThumbnail from "../images/project_thumbnails/vexucopro.png"
import WebsiteThumbnail from "../images/project_thumbnails/website.jpg"

const project_tiles = [
    {
        name: "Scara Webapp",
        short: "Webapp for controlling the Scara Arm (or any Marlin Device)",
        link: "/scara_webapp",
        img: ScaraWebappThumbnail,
        tags: [
            "React",
            "REST",
            "Django",
            "Python",
            "Web",
        ]
    },
    {
        name: "SolidWorks Webapp",
        short: "Connecting SolidWorks and Python to bring CI/CD to physical models.",
        link: "/solidworks",
        img: SolidWorksWebappThumbnail,
        tags: [
            "CAD",
            "React",
            "REST",
            "Django",
            "Python",
            "Web",
        ]
    },
    {
        name: "Scara Arm",
        short: "Designed and developed a 4 Degree of Freedom SCARA Arm. Mostly cause I've wanted to.",
        link: "/scara",
        img: ScaraThumbnail,
        tags: [
            "CAD",
            "Current",
            "Microcontroller",
            "Robotics",
        ]
    },
    {
        name: "CSCE 313 - Wikipedia Indexer",
        short: "Counting frequency of words in Wikipedia, 30 GB in less than 90 seconds.",
        link: "/wikipedia",
        img: null,
        tags: [
            "Big Data Big Speed",
            "C/C++",
            "Coursework",
        ]
    },
    {
        name: "CSCE 649 - Geometric Modeling",
        short: "GPU based polygon packing algorithm",
        link: "/polypack",
        img: PolyPackThumbnail,
        tags: [
            "C/C++",
            "Computational Geometry",
            "Coursework",
            "CUDA",
            "GPU",
        ]
    },
    {
        name: "CSCE 491 - Research",
        short: "My undergraduate research thesis, focused on solving the path planning problem for the 12d printer",
        link: "/urs",
        img: UrsThumbnail,
        tags: [
            "3D Printing",
            "12d Printer",
            "Big Data Big Speed",
            "C/C++",
            "Computational Geometry",
            "Coursework",
            "CUDA",
            "GPU",
        ]
    },
    {
        name: "VexU Co-Pro",
        short: "An attempt at integrating a co-processor with the Vex Robotics V5 brain over serial",
        link: "/vexu",
        img: VexUCoProThumbnail,
        tags: [
            "C/C++",
            "Competition",
            "Computer Vision",
            "Microcontroller",
            "Python",
            "Robotics",
            "VexU",
        ]
    },
    {
        name: "Spark Drive",
        short: "Dynamic switching of traction and omni wheels to change robot dynamics",
        link: "/spark",
        img: SparkTumbnail,
        tags: [
            "CAD",
            "Competition",
            "FRC",
        ]
    },
    {
        name: "Advent of Code",
        short: "My solutions to the Advent of Code puzzle series.",
        link: "/adventofcode",
        img: AdventOfCodeThumbnail,
        tags: [
            "Competition",
            "Python",
        ]
    },
    {
        name: "TA - Gradescope Grader",
        short: "Interfacing with Gradescope for grading student C++ code",
        link: "/gradescope",
        img: null,
        tags: [
            "C/C++",
            "Python",
            "TA",
            "Tools",
        ]
    },
    {
        name: "Marble Sorter",
        short: "My solution to Texas A&M's infamous (and discontinued) Marble sorter project",
        link: "/marblesorter",
        img: MarbleThumbnail,
        tags: [
            "CAD",
            "Coursework",
            "Matlab",
            "Robotics",
        ]
    },
    {
        name: "12d Printer",
        short: "A 3D printer with four heads in shared space working on one part. An outrageous idea thats a little too feasible.",
        link: "/12dprinter",
        img: Printer12dThumbnail,
        tags: [
            "12d Printer",
            "3D Printing",
            "CAD",
            "Robotics",
        ]
    },
    {
        name: "Facebook - Software Development Engineering Intern",
        short: "A brief overview of my time at Facebook and the tools I developed for the Network Analytics team",
        link: "/facebook",
        img: null,
        tags: [
            "C/C++",
            "Professional",
            "Python",
        ]
    },
    {
        name: "KidKraft Internship - Summer Engineering Intern",
        short: "A brief overview of my time at KidKraft, an international toy company headquartered in Dallas, TX.",
        link: "/kidkraft",
        img: null,
        tags: [
            "CAD",
            "Professional",
        ]
    },
    {
        name: "Southwest Innovation Research Lab - Lab Manager",
        short: "A brief overview of my time at Southwest Innovation Research Lab (SwIRL)",
        link: "/swirl",
        img: null,
        tags: [
            "Professional",
        ]
    },
    {
        name: "Website",
        short: "A short write up about creating this website",
        link: "/website",
        img: WebsiteThumbnail,
        tags: [
            "Current",
            "Web",
        ]
    },
    {
        name: "Summer Research",
        short: "Summer 2019 Research Project: 3D Printing of Continuous Carbon Fiber Thermoset using 5-Axis Robot Arm",
        link: "/reu",
        img: ReuThumbnail,
        tags: [
            "3D Printing",
            "Robotics",
            "Python",
        ]
    },
    {
        name: "CSCE 438 - Tiny SNS",
        short: "Implemented a very simple Twitter style service for a class with emphasis on failure handling and consensus on multiple machines",
        link: "/tinysns",
        img: null,
        tags: [
            "Coursework",
            "C/C++",
        ]
    },
    {
        name: "Aggie Robotics - Sumobots",
        short: "A novel, low-cost educational robotics platform based on ESP32 board and a local-hosted web-app",
        link: "/sumobots",
        img: SumobotsThumbnail,
        tags: [
            "Microcontroller",
            "Robotics",
            "Python",
            "Web",
        ]
    },
    {
        name: "Chillenium (2019)",
        short: "Our entry features a video game where the input system relies on a projector, laser pointer, and computer vision control loop",
        link: "/chillenium",
        img: ChilleniumThumbnail,
        tags: [
            "Competition",
            "Computer Vision",
        ]
    },
    {
        name: "CSCE 430 - Competitive Programming",
        short: "My experience in the competitive programming class at Texas A&M University",
        link: "/comppro",
        img: null,
        tags: [
            "C/C++",
            "Competition",
            "Coursework",
            "Python",
        ]
    },
    {
        name: "TA Auto Grader - Document Formatter",
        short: "A tool I wrote for grading student submissions when I was a TA",
        link: "/autoformatter",
        img: AutoFomatterThumbnail,
        tags: [
            "C/C++",
            "Python",
            "TA",
            "Tools",
        ]
    },
    {
        name: "CSCE 462 - Webapp Control of Stepper Motors",
        short: "A webapp interface to control stepper motors via a Raspberry Pi",
        link: "/cs462",
        img: CS462Thumbnail,
        tags: [
            "CAD",
            "Microcontroller",
            "Robotics",
            "Python",
            "Web",
        ]
    },
    {
        name: "FRC",
        short: "My time in First Robotics Competition (FRC)",
        link: "/frc",
        img: FrcThumbnail,
        tags: [
            "CAD",
            "Competition",
            "FRC",
            "Robotics",
        ]
    },
    {
        name: "CSCE 221 - Merge Sort by Switching",
        short: "A novel approach to writing merge sort that is very performant at the cost of O(N) memory overhead",
        link:"/mergesort",
        img: null,
        tags: [
            "Coursework",
            "C/C++",
        ]
    },
    {
        name: "TA - Assignment Example",
        short: "Example assignment given as a TA",
        link: "/taassignment",
        img: null,
        tags: [
            "TA",
        ]
    },
    {
        name: "Current projects",
        short: "Projects in progress or coming soon",
        link: "/current",
        img: null,
        tags: [
            "Current",
        ]
    },    
    // {
    //     name: "Selected other coursework: 420 (pacman, inference), 314 (haskell), 435, 463, business minor"
    // },
    // {
    //     name: "SEC indexer",
    //     link: "/sec"
    // },
];

const wrapperStyles = {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
}

const ProjectsPage = () => {
    const [filterTag, setNewTag] = useState(null);

    function _clickHandler(tag) {
        // console.log("Tag Clicked: " + tag);
        setNewTag(tag);
    } 

    function _r(){
        // render active
        if(filterTag === null){
            return;
        }

        return <div>Filtering: {filterTag} <button onClick={() => _clickHandler(null)}>X</button></div>
    }

    // build a list of all tags
    var s = new Set();

    project_tiles.forEach(element => {
        element.tags.forEach(t => {
            s.add(t);
        });
    });

    var l = [];

    s.forEach(e => {
        l.push(e);
    })

    l.sort();

    var pt_filtered = project_tiles

    if(filterTag !== null){
        // Now Filter the project tiles
        pt_filtered = pt_filtered.filter(t => t.tags.includes(filterTag));
    }

    return (
        <div>
            {HeaderBar()}
            <h1>Projects Page</h1>
            <div>By Technology: {l.map(tag => (
                ProjectTag(tag, () => {_clickHandler(tag)})
            ))}</div>
            {_r()}
            <hr></hr>
            <div style={wrapperStyles}>
                {pt_filtered.map(tile => (
                    ProjectTile(tile)
                ))}
            </div>
            {FooterBar()}
        </div>
    )
}


export default ProjectsPage
