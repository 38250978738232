import * as React from "react"

const tagStyle = {
    border: "1px solid blue",
    margin: "0 2px 0 2px",
    padding: "2px",
    borderRadius: "2px",
    display: "inline-block",
}

const buttonTag = {
    ...tagStyle,
    backgroundColor: "rgba(0,0,0,0)",
    cursor: "pointer",
}

function ProjectTag(prop, on_click = null) {
    // TODO - generate a color for the tag based on some hash of the tag

    if(on_click !== null){
        return <button style={buttonTag} key={prop} onClick={() => on_click()}>{prop}</button>
    }else{
        return (<div style={tagStyle} key={prop}>{prop}</div>);
    }
}

export default ProjectTag